import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom';
import {getCategory} from "../service/category";

export default function Links() {
    const {categoryId} = useParams()
    const [links, setLinks] = useState([])

    useEffect(() => {
        getCategory(categoryId)
            .then(category => setLinks(category.links))
    }, [categoryId])
    

    return (<div style={ { padding: 10 }}>
        {links.map(link => {
            return <div key={link.id}>
                <a href={link.link}>{link.title}</a>
            </div>
        })}
    </div>)
}
