import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AuthPage from "../AuthPage";
import {useStyles} from "../AuthPage";
import {Link} from "react-router-dom";


export default function Register(props) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isValid = email && password;


    function handleRegister(event) {
        if (event) {
            event.preventDefault()
        }

        const newuser = {email: email, password: password};
        const response = fetch("/api/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(newuser)
            })
                .then(response => {
                    if (response.status !== 200) {
                        throw Error('SMTH WENT WRONG');
                    }
                    return response.json();
                })
                .then(email => {
                    props.onLogin({email: email});
                })
        ;
        if (response.ok) {
            console.log("newuser registered!")
        }
    }

    return (<AuthPage title="Sign up" onSumbit={handleRegister}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Grid>
        </Grid>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid}
            className={classes.submit}
            onClick={handleRegister}
        >
            Sign Up
        </Button>
        <Grid container justify="flex-end">
            <Grid item>
                <Link to={"/login"} variant="body2">
                    Already have an account? Sign in
                </Link>
            </Grid>
        </Grid>
    </AuthPage>)
}