import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {useStyles} from "../AuthPage";


export default function AddCategory () {
    const [label, setCategory] = useState('');
    const isValid = label;
    const classes = useStyles();

    function handleSubmit(event) {
        if (event) {
            event.preventDefault()
        }
        const newcategory = {label};
        const response = fetch("/api/addcategory", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newcategory)
            })
                .then(response => {
                    if (response.status !== 200) {
                        throw Error('SMTH WENT WRONG');
                    }
                    return response.json();
                })
                .then(() => setCategory(""))
                .then(() => alert("new category added"))
        console.log('Response', response)
        if (response.ok) {
        console.log("new category added");
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Add new category
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="category"
                            label="Category"
                            name="category"
                            autoComplete="category"
                            autoFocus
                            value={label}
                            onChange={(event) => setCategory(event.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            Add
                        </Button>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}