import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AuthPage from "../AuthPage";
import {useStyles} from "../AuthPage";
import {Link} from "react-router-dom";

export default function Login(props) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isValid = email && password;

    function handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        const body = JSON.stringify({email: email, password: password});
        fetch('/api/login', {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json"
            },
            body: body
        })
            .then(response => {
                if (response.status !== 200) {
                    throw Error('SMTH WENT WRONG');
                }
                return response.json();
            })
            .then(email => {
                props.onLogin({email: email});
            })
    }

    return (<AuthPage title="Sign in" onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid}
                className={classes.submit}
                onClick={handleSubmit}
            >
                Sign In
            </Button>
            <Grid container justify="flex-end">
                <Grid item>
                    <Link to={"/register"} variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
        </AuthPage>
    )
}