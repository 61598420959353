import React, {Component} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Menu from "./components/Menu";
import Links from "./components/Links";
import Login from "./components/usermenu/Login";
import Register from "./components/usermenu/Register";
import AddCategory from "./components/usermenu/AddCategory";
import AddLink from "./components/usermenu/AddLink";


class MyLinks extends Component {

    state = {
        email: null
    };

    onLogin = email => {
        this.setState({email: email});
    };

    componentDidMount() {
        fetch('/api/user', {method: "POST"})
            .then(response => {
                return response.json();
            })
            .then(user => {
                if (user) {this.onLogin(user.email)}
            })
    }

    render() {
        const isLoggedIn = !!this.state.email;
        return (
            <Router>
                <div className="content">
                <Menu isLoggedIn={isLoggedIn}/>
                <Switch>
                    {!isLoggedIn &&
                        <Route path="/login">
                            <Login onLogin={this.onLogin}/>
                        </Route>}
                    {!isLoggedIn &&
                        <Route path="/register">
                            <Register onLogin={this.onLogin}/>
                        </Route>
                    }
                    <Route path="/category/:categoryId">
                        <Links/>
                    </Route>
                    {isLoggedIn && <Route path="/addcategory">
                        <AddCategory/>
                    </Route>}
                    {isLoggedIn && <Route path="/addlink">
                        <AddLink/>
                    </Route>}
                    <Redirect to="/category"/>
                </Switch>
                </div>
            </Router>
        )
    }
}

export default MyLinks