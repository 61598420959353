import React, {useEffect, useState} from 'react';
import {useStyles} from "../AuthPage";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";


export default function AddLink() {
    const [link, setLink] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const isValid = link && title && category;
    const classes = useStyles();

    function handleSubmit(event) {
        if (event) {
            event.preventDefault()
        }

        const newlink = {title: title, link: link, category_id: category};
        console.log(newlink)
        const response = fetch("/api/addlink", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newlink)
        })
            .then(response => {
                if (response.status !== 200) {
                    throw Error('SMTH WENT WRONG');
                }
                return response.json();
            })
            .then(() => setLink(""))
            .then(() => setCategory(""))
            .then(() => setTitle(""))
            .then(() => alert("new link added"))
        if (response.ok) {
            console.log("new category added")
        }
    }

    useEffect(() => {
        fetch('/api/category', {credentials: 'include'})
            .then(response =>
                response.json().then(data => {
                    setCategories(data);
                })
            );
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Add new link
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="link"
                            label="Link"
                            name="link"
                            autoComplete="link"
                            autoFocus
                            value={link}
                            onChange={(event) => setLink(event.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoComplete="title"
                            autoFocus
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                required
                                value={category}
                                onChange={(event) => setCategory(event.target.value)}
                                label="Category"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {categories.map(category => (
                                    <MenuItem value={category.id} key={category.id}>
                                        {category.label}
                                    </MenuItem>))}
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            Add
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link to={"/addcategory"} variant="body2">
                                    {"Need a new category?"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )

}