import React, {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom";
import {getCategories} from "../service/category";


export default function Menu({isLoggedIn}) {
    const [categories, setCategories] = useState([]);
    const history = useHistory()

    useEffect(() => {
        getCategories()
            .then(data => {
                setCategories(data);
                // if (data.length >0) {
                //     history.push(`/category/${data[0].id}`)
                // }
            })
    }, [history]);

    // function logoutHandler() {
    //
    //     history.push("/")
    // }
    
    return (
        <div className={"header"}>
            <nav className="navbar navbar-expand-sm">
                {categories.map(category => (
                    <Link className="btn btn-outline-dark mx-1 my-1" key={category.id} to={`/category/${category.id}`}>
                        {category.label}
                    </Link>))}
            </nav>
            <nav className="navbar navbar-expand-sm">
                {!isLoggedIn &&
                <Link className="btn btn-outline-dark mx-1 my-1" to={'/login'}>Login</Link>}
                {!isLoggedIn &&
                <Link className="btn btn-outline-dark mx-1 my-1" to={'/register'}>Register</Link>}
                {isLoggedIn &&
                <Link className="btn btn-outline-dark mx-1 my-1" to={'/addcategory'}>Add Category</Link>}
                {isLoggedIn &&
                <Link className="btn btn-outline-dark mx-1 my-1" to={'/addlink'}>Add Link</Link>}
                {/*{isLoggedIn &&*/}
                {/*<Link className="btn btn-outline-dark mx-1 my-1" to={'#'} onClick={logoutHandler}>Log Out</Link>}*/}
            </nav>
        </div>)
}